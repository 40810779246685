
import React, { useRef, useEffect } from 'react';

import Glider, { GliderMethods } from 'react-glider';
import 'glider-js/glider.min.css';

import { TestimonialsOneData } from "@/data";
import "@/css/testimonials.css";
// https://stackoverflow.com/questions/62122998/implementing-autoplay-on-glider-js
//https://github.com/NickPiscitelli/Glider.js/issues/43
//https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react
//https://reactjs.org/docs/hooks-effect.html
const Testimonials = () => {
  const { posts } = TestimonialsOneData;

  const slideCount = posts ? posts.length - 1 || 0 : posts.length - 1
  const gliderRef = useRef(null);
  const timeoutRef = useRef(null);

  const delay = 5000;
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    onAnimated();

    return () => {
      resetTimeout();
    };
  }, []);

  const invokeSetTimeout = () => {
    setTimeout(() => {
      const slideTo = () => {
        const isLastSlide = gliderRef.current.page >= slideCount;
        return isLastSlide ? 0 : gliderRef.current.page + 1;
      }
      if (gliderRef && gliderRef.current && gliderRef.current.scrollItem) {
        gliderRef.current.scrollItem(slideTo(), true);
      }
    }, delay);
  };

  const onAnimated = () => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      invokeSetTimeout();
    }, delay)
  }

  return (
    <figure className='testimonials'>
      <h5 className="title">Testimonials</h5>
      <Glider
        ref={gliderRef}
        onAnimated={onAnimated}
        hasDots
        slidesToShow={1}
        slidesToScroll={1}
      >
        {
          posts.map(({ content, name }, index) => {
            return (
              <Pane key={index} className={"slide"}>
                <blockquote>{content}</blockquote>
                <figcaption>{name}</figcaption>
              </Pane>)
          })
        }
      </Glider>
    </figure>
  )
}

const Pane = ({ children, style, className }) => (
  <div className={`glider-slide ${className || ''}`} style={style}>
    <p>{children}</p>
  </div>
);

export default Testimonials;